<template>
  <div>
    <highcharts
      ref="timelineChart"
      class="chart"
      :options="chartOptions"
      :updateArgs="updateArgs"
    ></highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import moment from "moment-timezone";
window.moment = moment;

export default {
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "line"
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false
            }
          }
        },
        title: {
          text: "",
          align: "center",
          verticalAlign: "top",
          style: {
            fontSize: "14px",
            lineHeight: "17px",
            color: "#A0A8B5"
          }
        },
        xAxis: {
          type: "datetime",
          tickPixelInterval: 150
        },
        yAxis: {
          title: {
            text: ""
          }
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          layout: "vertical",
          x: 0,
          y: 20,
          width: "30%",
          symbolHeight: 14,
          symbolWidth: 14,
          symbolRadius: 0,
          symbolPadding: 20,
          padding: 3,
          itemMarginBottom: 10,
          itemStyle: {
            lineHeight: "16px",
            color: "#454545",
            fontWeight: "normal"
          }
        },
        series: []
      },
      timelineMapped: []
    };
  },

  methods: {
    reloadChart() {
      this.timelineMapped = [];
      let timelineChart = this.$refs["timelineChart"].chart;
      while (timelineChart.series.length > 0) {
        timelineChart.series[0].remove(true);
      }
      timelineChart.redraw();
    },

    updateChart(data, title) {
      if (this.$refs["timelineChart"]) {
        this.$refs["timelineChart"].chart.setTitle({
          text: title
        });
        let timelineChart = this.$refs["timelineChart"].chart;
        let x = new Date().getTime();
        let y = 0;

        // Checking if the series is in the newly passed in data. If it is not, it is adding a point with a value of 0.
        for (let ser in timelineChart.series) {
          let seriesId = timelineChart.series[ser].options.id;
          let dataIndex = data.findIndex(ele => ele.id == seriesId);
          if (dataIndex < 0) {
            if (timelineChart.series[ser].options.data.length >= 300) {
              timelineChart.series[ser].addPoint([x, 0], true, true);
            } else {
              timelineChart.series[ser].addPoint([x, 0], false, false);
            }
          }
        }

        for (let i in data) {
          if (!timelineChart.get(data[i].id)) {
            timelineChart.addSeries({
              id: data[i].id,
              name: data[i].name,
              data: [],
              color: data[i].color
            });
          }

          for (let ser in timelineChart.series) {
            if (timelineChart.series[ser].options.id == data[i].id) {
              this.timelineMapped.push(data[i].id);
              y = data[i].y;
              if (timelineChart.series[ser].options.data.length >= 300) {
                timelineChart.series[ser].addPoint([x, y], true, true);
              } else {
                timelineChart.series[ser].addPoint([x, y], false, false);
              }
            }
          }
        }

        if (
          Object.keys(this.timelineMapped).length !==
          Object.keys(timelineChart.series).length
        ) {
          for (let ser in timelineChart.series) {
            if (
              !this.timelineMapped.includes(
                timelineChart.series[ser].options.id
              )
            ) {
              if (timelineChart.series[ser].options.data.length >= 300) {
                timelineChart.series[ser].addPoint([x, 0], true, true);
              } else {
                timelineChart.series[ser].addPoint([x, 0], false, false);
              }
            }
          }
        }
        timelineChart.redraw();
      }
    },

    setTitle(title) {
      if (this.$refs["timelineChart"]) {
        this.$refs["timelineChart"].chart.setTitle({
          text: title
        });
      }
    }
  },

  mounted() {
    Highcharts.setOptions({
      time: {
        timezone: this.$auth.user().default_timezone
      }
    });
  }
};
</script>
