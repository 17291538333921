<template>
  <div>
    <highcharts
      ref="pieChart"
      class="chart"
      :options="chartOptions"
      :updateArgs="updateArgs"
    ></highcharts>
  </div>
</template>

<script>
import Drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts";
import { EventBus } from "@/EventBus";

Drilldown(Highcharts);
Highcharts.setOptions({
  lang: {
    drillUpText: "< " + __("Back")
  }
});

export default {
  data() {
    return {
      subtitlePositionX: -118,
      chartOptions: {
        chart: {
          type: "pie",
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          events: {
            drilldown(e) {
              if (!e.seriesOptions) {
                const chart = this;
                const series = e.point.drilldown_data;
                const parentId = e.point.parent_id;
                EventBus.$emit("drilldown", { series, parentId });

                chart.showLoading("Loading...");
                chart.addSeriesAsDrilldown(e.point, series);
                chart.applyDrilldown();
                setTimeout(function() {
                  chart.hideLoading();
                }, 1000);
              }
            },

            drillup() {
              let chart = this;
              chart.showLoading(__("Loading") + "...");
              EventBus.$emit("drillup");
              setTimeout(function() {
                chart.hideLoading();
              }, 1000);
            },

            render: function() {
              let seriesCenter = this.series[0].center;
              if (seriesCenter && seriesCenter.length) {
                let pieCenterX = 0 - Math.round(seriesCenter[0] / 2);
                this.setSubtitle({
                  x: pieCenterX + this.plotLeft
                });
              }
            }
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            showInLegend: true,
            innerSize: "50%",
            borderWidth: 5,
            size: 300,
            center: ["50%", "50%"]
          }
        },
        legend: {
          align: "right",
          verticalAlign: "top",
          layout: "vertical",
          x: 0,
          y: 20,
          width: "30%",
          symbolHeight: 14,
          symbolWidth: 14,
          symbolRadius: 0,
          symbolPadding: 20,
          padding: 3,
          itemMarginBottom: 10,
          itemStyle: {
            lineHeight: "16px",
            color: "#454545",
            fontWeight: "normal"
          }
        },
        exporting: {
          enabled: false
        },
        title: {
          text: "",
          align: "center",
          verticalAlign: "top",
          style: {
            fontSize: "14px",
            lineHeight: "17px",
            color: "#A0A8B5"
          }
        },
        series: [
          {
            name: "Live sessions",
            data: [
              {
                name: "No session",
                y: 0,
                drilldown: 0
              }
            ]
          }
        ],
        subtitle: {
          text: "0",
          floating: false,
          verticalAlign: "middle",
          align: "center",
          x: -200,
          style: {
            fontSize: "36px",
            color: "#454545"
          }
        },
        drilldown: {
          drillUpButton: {
            relativeTo: "spacingBox",
            position: {
              align: "left"
            },
            theme: {
              fill: "white",
              "stroke-width": 0,
              stroke: "silver",
              r: 0
            }
          }
        }
      },
      updateArgs: [true, true, { duration: 1000 }]
    };
  },

  methods: {
    // reload Pie chart, drill up all levels
    reloadChart() {
      if (this.$refs["pieChart"]) {
        this.$refs["pieChart"].chart.drillUpAll();
      }
    },
    updateChart(data, totalSession, title) {
      if (this.$refs["pieChart"]) {
        this.$refs["pieChart"].chart.setTitle({
          text: title
        });
        if (data && data.length) {
          this.$refs["pieChart"].chart.series[0].setData(data);
        } else {
          this.$refs["pieChart"].chart.series[0].setData({
            name: "Live sessions",
            data: [
              {
                name: "No session",
                y: 0,
                drilldown: 0
              }
            ]
          });
        }
        this.$refs["pieChart"].chart.setSubtitle({
          useHtml: true,
          x: totalSession > 0 ? -200 : -115,
          text:
            totalSession.toString() +
            '</br><span style="font-size: 14px !important;">' +
            __("Agents") +
            "</span>"
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .highcharts-container {
  .highcharts-subtitle {
    transform: translateX(22.5%);
  }
}
</style>
