<template>
  <div>
    <vxe-table
      ref="xTable"
      :align="allAlign"
      :data="tableData"
      border="inner"
      highlight-current-row
      @current-change="handleCurrentChange"
      v-loading="listLoading"
      :max-height="500"
      show-overflow="tooltip"
      :tooltip-config="{ enterable: true }"
    >
      <vxe-table-column
        v-for="(column, index) in tableColumns"
        :key="'_column_' + index"
        v-bind="column"
        :sortable="column.sortable"
        :type="column.type"
      >
        <template v-if="column.type == 'expand'" v-slot:content="{ row }">
          <vxe-grid
            border="none"
            :columns="column.expand_table_columns"
            :data="row[column.expand_key]"
          >
            <template v-slot:empty>
              <p>{{ __("No data") }}</p>
            </template>
          </vxe-grid>
        </template>
      </vxe-table-column>
      <template v-slot:empty>
        <p>{{ __("No data") }}</p>
      </template>
    </vxe-table>
  </div>
</template>

<script>
import request from "@/utils/studio7ApiService";

export default {
  props: {
    baseUrl: {
      required: true
    },
    tableData: {
      type: Array,
      default: null
    },
    resizable: {
      default: false
    }
  },
  data() {
    return {
      allAlign: null,
      tableColumns: null,
      listLoading: false
    };
  },

  computed: {
    tableAttributes() {
      let attributes = {};
      if (this.resizable) {
        attributes.resizable = true;
      }
      return attributes;
    }
  },

  methods: {
    getTableColumns() {
      return request({
        url: this.baseUrl + "/table-columns",
        method: "get"
      });
    },

    handleCurrentChange({ row }) {
      this.$emit("row-click", row);
    },

    exportData() {
      this.$refs.xTable.exportData({ type: "csv" });
    },

    clearCurrentRow() {
      this.$refs.xTable.clearCurrentRow();
    }
  },

  async mounted() {
    this.listLoading = true;
    await this.getTableColumns().then(data => {
      this.tableColumns = [...data.data];
    });
    this.listLoading = false;
  }
};
</script>

<style lang="scss">
.vxe-cell--title {
  color: #a0a8b5 !important;
}

.vxe-body--expanded-row {
  .border--none {
    .vxe-body--column,
    vxe-header--column {
      background: none;
    }
  }
}
</style>
