<template>
  <el-main v-loading.fullscreen.lock="fullscreenLoading">
    <el-row>
      <el-col :span="6">
        <el-input-number
          v-model="minsNum"
          :placeholder="__('minutes')"
          :min="0"
          size="small"
          controls-position="right"
          style="height: 100%;"
        ></el-input-number>
        <el-button
          class="clearStaleCalls"
          style="color: #4db3f6; margin-left:5px"
          v-on:click="handleClearStaleCalls()"
          :disabled="!minsNum"
          >{{ __("Clear Stale Calls") }}</el-button
        >
      </el-col>
    </el-row>
    <el-row :gutter="20" :key="componentKey">
      <el-col :span="10">
        <div class="title">{{ __("Live Breakdown") }}</div>
        <div class="box" v-loading="isLoading">
          <breakdown-pie-chart
            ref="liveBreakdownBaseChart"
            @drilldown="handleDrilldown"
            is-loading="isLoading"
          ></breakdown-pie-chart>
        </div>
      </el-col>
      <el-col :span="14">
        <div class="title">{{ __("Timeline") }}</div>
        <div class="box" v-loading="isLoading">
          <timeline-chart
            ref="timelineChart"
            is-loading="isLoading"
          ></timeline-chart>
        </div>
      </el-col>
    </el-row>

    <el-row style="padding-top: 20px;">
      <div class="title">{{ __("Live Call Details Overview") }}</div>
      <div class="box">
        <base-table
          ref="liveCallTable"
          v-loading="isLoading"
          base-url="/opensip/active-calls"
          :table-data="tableData"
        ></base-table>
      </div>
    </el-row>
  </el-main>
</template>

<script>
import BaseTable from "@/layout/base-contents/components/BaseTable";
import BreakdownPieChart from "../components/breakdownPieChart";
import TimelineChart from "../components/activeCallTimeline";
import { mapActions, mapState } from "vuex";
let randomColor = require("randomcolor");
import _ from "lodash";
import { EventBus } from "@/EventBus";
import moment from "moment-timezone";

export default {
  name: "LiveVirtualAgentTracker",
  components: {
    BaseTable,
    BreakdownPieChart,
    TimelineChart
  },
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      backendTimezone: state => state.backendTimezone,
      userAccountSwitcherOptions: state => state.userAccountSwitcherOptions
    }),

    // Mapping the state of the activecalls module to the activeCalls property.
    ...mapState("activecalls", {
      activeCalls: state => state.activeCalls
    }),

    // A computed property that is used to watch for changes in the selectedServiceProviderId and selectedAccountId.
    accountSwitcher() {
      return `${this.selectedServiceProviderId}|${this.selectedAccountId}`;
    },

    // Returning the drill down levels based on selected account and service provider
    drilldownLevels() {
      if (
        this.selectedAccountId !== "all" &&
        this.selectedServiceProviderId !== "all"
      ) {
        return ["task"];
      } else if (
        this.selectedServiceProviderId !== "all" &&
        this.selectedAccountId == "all"
      ) {
        return ["ac", "task"];
      } else {
        return ["sp", "ac", "task"];
      }
    },

    // Returning the title of the chart based on selected account and service provider
    chartTitle() {
      let chartTitle = __("Showing - All") + " ";
      if (this.drilldownLevels[0] == "sp") {
        chartTitle = __(":chartTitle Service Providers", {
          chartTitle: chartTitle
        });
      } else if (this.drilldownLevels[0] == "ac") {
        chartTitle = __(":chartTitle Accounts", { chartTitle: chartTitle });
      } else {
        chartTitle = __(":chartTitle Tasks", { chartTitle: chartTitle });
      }
      return chartTitle;
    },

    // In order to watch changes in spIdsInActiveCalls array
    parsedSpIdsInActiveCalls() {
      return JSON.parse(JSON.stringify(this.spIdsInActiveCalls));
    }
  },
  data() {
    return {
      isDrilledDown: false,
      drilldownFound: false,
      drilldownId: null,
      drilldownParentId: null,
      realTimeInterval: null,
      isLoading: false,
      matchedColor: {},
      tableData: [],
      groupedData: [],
      drilldownData: [],
      currentDrilldownLevel: "sp",
      fullscreenLoading: false,
      sessionTrackerChannelName: "live-virtual-agent-tracker",
      now: Date.now(),
      componentKey: 0,
      apiScope: "ac",
      minsNum: undefined,
      spIdsInActiveCalls: [],
      spTasksMap: []
    };
  },

  methods: {
    ...mapActions("app", {
      changeUserSelectedAccountId: "changeUserSelectedAccountId",
      getEnabledFeaturesForUser: "getEnabledFeaturesForUser"
    }),

    ...mapActions("activecalls", {
      getActiveCalls: "getActiveCalls",
      clearStaleCalls: "clearStaleCalls",
      setActiveCalls: "setActiveCalls"
    }),

    ...mapActions("tasks", {
      getTasksForAccounts: "getTasksForAccounts"
    }),

    // Updating the duration of call value and using the newly formed data to update the timeline chart
    updateTableDataAndGroupedData() {
      this.tableData = [];
      _.each(this.activeCalls, (item, index) => {
        if (!item.task_name) {
          item = this.addTaskName(item);
          this.$set(this.activeCalls, index, item);
        }
        let drilldownId =
          this.currentDrilldownLevel +
          "_id_" +
          item[this.currentDrilldownLevel + "_id"];

        if (
          !this.isDrilledDown ||
          (this.isDrilledDown && this.drilldownId === drilldownId)
        ) {
          let created_at_ts = new Date(item.created_at) / 1000;
          let backend_now = this.now;
          if (this.backendTimezone) {
            let backend_tz_now = moment(this.now)
              .tz(this.backendTimezone)
              .format("YYYY-MM-DD HH:mm:ss");
            backend_now = moment(backend_tz_now).valueOf();
          } else {
            created_at_ts = item.created_at_ts || created_at_ts;
          }

          let timeDiff = Math.floor(backend_now / 1000 - created_at_ts);

          let hours = Math.floor(timeDiff / 3600);
          let minutes = (
            Math.floor(timeDiff / 60) % 60
          ).toLocaleString(undefined, { minimumIntegerDigits: 2 });
          let seconds = (timeDiff % 60).toLocaleString(undefined, {
            minimumIntegerDigits: 2
          });
          item.duration = `${hours}:${minutes}:${seconds}`;
          this.$set(this.tableData, index, item);
        }
      });

      this.groupedData = this.groupDataForLevel(
        this.tableData,
        this.currentDrilldownLevel
      );

      if (this.isDrilledDown) {
        this.$refs["timelineChart"].updateChart(
          this.drilldownData,
          this.chartTitle
        );
      } else {
        this.$refs["timelineChart"].updateChart(
          this.groupedData,
          this.chartTitle
        );
      }
    },

    // Getting sp name and ac name from app state and adding to each active call
    addSpAndAcName(call) {
      let activeCallSp = this.userAccountSwitcherOptions.find(
        sp => sp.sp_id === call.sp_id
      );
      if (activeCallSp) {
        call.sp_name = activeCallSp.sp_name;
        let activeCallAc = activeCallSp.accounts.find(
          acc => acc.ac_id === call.ac_id
        );
        if (activeCallAc) {
          call.ac_name = activeCallAc.ac_name;
        }
      }
      return call;
    },

    // Getting task name from spTasksMap and adding to each active call
    addTaskName(call) {
      const acTasks = this.spTasksMap.find(item => item.ac_id === call.ac_id);
      if (acTasks) {
        const task = acTasks.tasks.find(task => task.task_id === call.task_id);
        if (task) {
          call.task_name = task.task_name;
        }
      }
      return call;
    },

    handleDrilldown(drilldown) {
      this.isLoading = true;
      this.isDrilledDown = true;
      this.drilldownId = drilldown.series.id;
      this.currentDrilldownLevel = drilldown.series.level;
      this.drilldownParentId = drilldown.parentId;

      this.$refs["timelineChart"].reloadChart();
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },

    handleDrillup() {
      this.isLoading = true;
      let index = this.drilldownLevels.indexOf(this.currentDrilldownLevel);
      if (index > 0) {
        this.currentDrilldownLevel = this.drilldownLevels[index - 1];
      } else {
        this.currentDrilldownLevel = null;
      }

      if (this.currentDrilldownLevel === null) {
        this.isDrilledDown = false;
        this.drilldownId = null;
        this.drilldownParentId = null;
      } else {
        this.drilldownId = this.drilldownParentId;
      }
      this.$refs["timelineChart"].reloadChart();
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    handleClearStaleCalls() {
      this.clearStaleCalls(this.minsNum)
        .then(data => {
          if (data.removed_records > 0) {
            this.$message({
              type: "success",
              message: __("Successfully Removed :removed Records", {
                removed: data.removed_records
              })
            });
          } else if (data.removed_records === 0) {
            this.$message({
              type: "info",
              message: __("No Records Found To Delete")
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },

    // Listening to the event "LiveVirtualAgentUpdated" and when it is triggered, it will receive event payload
    joinLiveVirtualAgentTrackerChannel() {
      this.$echo
        .channel(this.sessionTrackerChannelName)
        .listen("LiveVirtualAgentUpdated", event => {
          let currentActiveCalls = this.activeCalls.slice();
          if (
            this.selectedAccountId === "all" ||
            event.active_call.ac_id === this.selectedAccountId
          ) {
            let userHasAccessToAccount = this.checkIfUserHasAccessToAccount(
              event.active_call
            );
            if (userHasAccessToAccount) {
              currentActiveCalls = this.addOrRemoveActiveCall(
                currentActiveCalls,
                event.active_call
              );
            }
          }

          currentActiveCalls.forEach((item, index) => {
            if (!item.sp_name) {
              currentActiveCalls[index] = this.addSpAndAcName(item);
            }
            if (!item.task_name) {
              currentActiveCalls[index] = this.addTaskName(item);
            }
          });
          this.setActiveCalls(currentActiveCalls);

          this.updateTableDataAndGroupedData();
          if (this.isDrilledDown) {
            this.updatePieChart(this.drilldownData);
          } else {
            this.updatePieChart(this.groupedData);
          }
        });
    },

    checkIfUserHasAccessToAccount(active_call) {
      const service_provider = this.userAccountSwitcherOptions.find(
        item => item.sp_id === active_call.sp_id
      );
      return service_provider
        ? service_provider.accounts.some(obj => obj.ac_id === active_call.ac_id)
        : false;
    },

    // Getting the current active calls based on the received new active call payload
    addOrRemoveActiveCall(currentActiveCalls, payload) {
      const existingCallIndex = currentActiveCalls.findIndex(
        item => item.session_id === payload.session_id
      );
      if (existingCallIndex !== -1) {
        // If the existing call is found, the newly received payload means the call has just ended, remove it from the array
        currentActiveCalls.splice(existingCallIndex, 1);
      } else {
        if (this.backendTimezone) {
          payload.created_at = moment
            .utc(payload.created_at)
            .tz(this.backendTimezone)
            .format("YYYY-MM-DD HH:mm:ss");
        }
        currentActiveCalls.push(payload);
        if (
          this.selectedServiceProviderId === "all" &&
          !this.spIdsInActiveCalls.includes(payload.sp_id)
        ) {
          this.spIdsInActiveCalls.push(payload.sp_id);
        }
      }
      return currentActiveCalls;
    },

    // Getting the data from the API and updating the table and pie chart.
    async getLiveVirtualAgentData() {
      let scope = "";
      if (
        this.selectedAccountId !== "all" &&
        this.selectedServiceProviderId !== "all"
      ) {
        scope = "ac";
      } else if (
        this.selectedServiceProviderId !== "all" &&
        this.selectedAccountId == "all"
      ) {
        scope = "sp";
      }
      await this.getActiveCalls(scope);
      _.forEach(this.activeCalls, (item, index) => {
        item = this.addSpAndAcName(item);
        this.$set(this.activeCalls, index, item);
      });
      this.updateTableDataAndGroupedData();
      if (this.isDrilledDown) {
        this.updatePieChart(this.drilldownData);
      } else {
        this.updatePieChart(this.groupedData);
      }
    },

    // Updating the chart with the new data.
    updatePieChart(groupedData) {
      this.$refs["liveBreakdownBaseChart"].updateChart(
        groupedData,
        groupedData.reduce((a, b) => {
          return a + b.y;
        }, 0),
        this.chartTitle
      );
    },

    generateColor(key) {
      if (!_.has(this.matchedColor, "key")) {
        this.$set(
          this.matchedColor,
          key,
          randomColor({
            luminosity: "dark",
            format: "rgba",
            alpha: 0.5
          })
        );
      }
      return this.matchedColor[key];
    },

    groupDataForLevel(data, currentlevel) {
      let result = [];
      // use the first level if passed in null value
      if (currentlevel === null) {
        currentlevel = this.drilldownLevels[0];
      }
      let groupedData = _.groupBy(data, currentlevel + "_id");
      if (Object.keys(groupedData).length > 0) {
        for (let id in groupedData) {
          if (this.drilldownFound) {
            break;
          }

          if (id !== undefined) {
            let totalSession = Object.keys(groupedData[id]).length;
            if (totalSession > 0) {
              let singleGroupData = {
                id: id,
                name: groupedData[id][0][currentlevel + "_name"],
                y: totalSession,
                color: this.generateColor(currentlevel + "_id_" + id)
              };

              let i = this.drilldownLevels.indexOf(currentlevel);

              if (i > 0) {
                let parentIdKey = this.drilldownLevels[i - 1] + "_id";
                const parentIdValue = groupedData[id][0][parentIdKey];
                singleGroupData.parent_id = parentIdKey + "_" + parentIdValue;
              } else {
                singleGroupData.parent_id = null;
              }

              if (i !== -1 && i < this.drilldownLevels.length - 1) {
                const drilldownData = this.groupDataForLevel(
                  groupedData[id],
                  this.drilldownLevels[i + 1]
                );
                singleGroupData.drilldown = currentlevel + "_id_" + id;
                singleGroupData.drilldown_data = {
                  id: currentlevel + "_id_" + id,
                  level: currentlevel,
                  data: drilldownData
                };
                if (
                  this.isDrilledDown &&
                  this.drilldownId == currentlevel + "_id_" + id
                ) {
                  this.drilldownData = drilldownData;
                  this.drilldownFound = true;
                }
              }
              result.push(singleGroupData);
            }
          }
        }
      }
      this.drilldownFound = false;
      return result;
    },

    // Initializing the chart.
    initCharts() {
      this.isDrilledDown = false;
      this.drilldownFound = false;
      this.drilldownId = null;
      this.drilldownParentId = null;
      this.tableData = [];
      this.groupedData = [];
      this.drilldownData = [];
      this.currentDrilldownLevel = this.drilldownLevels[0];
      this.$refs["timelineChart"].reloadChart();
      this.getLiveVirtualAgentData();
    }
  },

  created() {
    EventBus.$on("drilldown", data => {
      this.handleDrilldown(data);
    });

    EventBus.$on("drillup", data => {
      this.handleDrillup(data);
    });
  },
  mounted() {
    this.initCharts();
    let self = this;
    setTimeout(() => {
      self.realTimeInterval = setInterval(function() {
        self.now = Date.now();
      }, 1000);
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.realTimeInterval);

    this.$echo.leave(this.sessionTrackerChannelName);
  },

  watch: {
    now: {
      handler: "updateTableDataAndGroupedData"
    },

    // A watcher that is watching the accountSwitcher variable. When the accountSwitcher variable changes, it will run the
    // initCharts() function.
    accountSwitcher(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.initCharts();
          this.componentKey++;
        }, 1000);
        this.fullscreenLoading = false;
      }
    },

    selectedServiceProviderId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$echo.leave(this.sessionTrackerChannelName);
          this.sessionTrackerChannelName =
            "live-virtual-agent-tracker." + newVal;
          this.joinLiveVirtualAgentTrackerChannel();
          if (newVal !== "all") {
            this.spIdsInActiveCalls = [this.selectedServiceProviderId];
            this.spTasksMap = [];
          }
        }
      },
      immediate: true
    },

    //  When new sp id is found from active calls, call getTasksForAccounts to get tasks under this new sp
    parsedSpIdsInActiveCalls: {
      handler(newVal, oldVal) {
        let newSpIds;
        if (oldVal) {
          newSpIds = newVal.filter(item => !oldVal.includes(item));
        } else {
          newSpIds = newVal;
        }
        if (newSpIds.length > 0) {
          this.getTasksForAccounts({
            spId: newSpIds[0],
            accIds: "all",
            taskType: "voice"
          }).then(({ data }) => {
            if (data) {
              this.spTasksMap = [...this.spTasksMap, ...data];
            }
          });
        }
      },
      immediate: true
    },

    activeCalls: {
      handler() {
        if (this.isDrilledDown) {
          this.updatePieChart(this.drilldownData);
        } else {
          this.updatePieChart(this.groupedData);
        }
      }
    }
  }
};
</script>

<style scoped>
.box {
  padding: 20px;
  border: 1px solid #f5f5f8;
  box-sizing: border-box;
  border-radius: 5px;
}

.title {
  padding: 20px 0;
  font-size: 14px;
  line-height: 17px;
  color: #454545;
}
</style>
